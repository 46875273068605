<template>
    <div v-if="!data.data.length" class="text-center pt-14 pb-48">
        <p class="text-gray-700 text-base">You don't have any surveys yet.</p>
        <NuxtLink to="/form/create?type=survey"><button class="text-primary text-sm">Create new survey</button></NuxtLink>
    </div>
    <div v-else>
        <div v-for="survey in data.data" class="flex items-center h-20 justify-between px-6">
            <div>
                <p class="text-xl text-black font-medium"> {{ survey.name }} </p>
                <p class="text-base text-gray-700">% Completed</p>
            </div>
            <span class="text-base text-gray-700 ml-auto mr-10">Due: 12/31/24</span>
            <div class="flex items-center">
                <NuxtLink :to="`/form/${survey.slug}`">
                    <button class="text-black border-b border-black mr-4">View</button>
                </NuxtLink>
                <button class="text-black mr-2 flex items-center">
                    <span class="border-b border-black">Send</span>
                    <font-awesome class="ml-2 h-5 w-5" :icon="['far', 'paper-plane-top']" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    const { data } = await useFetch('/api/test-org-2/forms', { query: { type: 'survey' }})
</script>

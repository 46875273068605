<template>
    <div class="flex-1 bg-gray-100 flex flex-col items-center gap-6 py-6">
        <div class="max-w-3xl w-full">
            <h1 class="text-2xl mb-8">All Surveys And Forms!</h1>
            <div class="bg-white border border-gray-400">
                <div class="flex justify-between px-6">
                    <div class="flex items-end">
                        <button class="text-gray-700 border-b-2 border-white text-base p-3 font-medium" :class="{ '!border-primary font-bold text-primary': tab === Tabs.Recents}" type="button" @click.prevent="() => setTab(Tabs.Recents)">
                            <font-awesome :icon="['fas', 'star']" class="mr-1" /> Recents
                        </button>
                        <button class="text-gray-700 border-b-2 border-white text-base p-3 font-medium" :class="{ '!border-primary font-bold text-primary': tab === Tabs.Surveys}" type="button" @click.prevent="() => setTab(Tabs.Surveys)">
                            <font-awesome :icon="['fad', 'ballot-check']" class="mr-1" /> All surveys
                        </button>
                        <button class="text-gray-700 border-b-2 border-white text-base p-3 font-medium" :class="{ '!border-primary font-bold text-primary': tab === Tabs.Forms}" type="button" @click.prevent="() => setTab(Tabs.Forms)">
                            <font-awesome :icon="['fad', 'id-card']" class="mr-1" /> All forms
                        </button>
                    </div>
                    <div class="my-6 relative" v-click-outside="() => createDropdownOpen = false">
                        <Button type="primary" @click="createDropdownOpen = !createDropdownOpen" :icon="['fas', 'plus']" :right-icon="['fas', 'angle-down']">
                            New
                        </Button>
                        <div v-show="createDropdownOpen" class="absolute top-10 right-6 w-52 bg-white rounded-lg border border-gray-100 drop-shadow-lg px-2 py-2">
                            <NuxtLink to="/form/create?type=survey">
                                <button class="flex items-center text-gray-700 text-base px-2 py-1 rounded hover:bg-gray-200 w-full mb-2"><font-awesome :icon="['far', 'ballot-check']" class="w-5 h-5 mr-2" /> Survey</button>
                            </NuxtLink>
                            <NuxtLink to="/form/create?type=form">
                                <button class="flex items-center text-gray-700 text-base px-2 rounded hover:bg-gray-200 w-full"><font-awesome :icon="['far', 'id-card']" class="w-5 h-5 mr-2" /> Form</button>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="flex items-center gap-6 px-6 h-16 bg-gray-100 border-y border-gray-400">
                    <label class="flex items-center rounded-full bg-gray-200 border border-gray-400 h-10 p-2 text-sm text-black">
                        <font-awesome :icon="['fas', 'magnifying-glass']" class="h-4 w-4 ml-2 mr-1" />
                        <input class="bg-gray-200 placeholder-black outline-none" placeholder="Search all data forms" />
                    </label>
                    <button>Filter</button>
                    <button>Sort</button>
                </div>
                <div>
                    <div v-show="tab === Tabs.Recents"> Recents </div>
                    <SurveysList v-show="tab === Tabs.Surveys" />
                    <FormsList v-show="tab === Tabs.Forms" />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    [data-prefix="fad"] path:nth-child(1) {
        opacity: 0.4;
    }

    :root {
        --primary: #4068EB;
        --secondary: #4B6C92;
        --background-dim: #F9FBFD;
    }
</style>

<script setup lang="ts">
    import SurveysList from "~/components/dashboard/SurveysList.vue";
    import FormsList from "~/components/dashboard/FormsList.vue";
    import Button from "~/components/ui/Button.vue";

    enum Tabs {
        Recents = 'recents',
        Forms = 'forms',
        Surveys = 'surveys',
    }

    const createDropdownOpen = ref(false)

    const route = useRoute()
    const router = useRouter()

    const tab = ref(route.query.tab || Tabs.Forms)
    const setTab = (newTab: Tabs) => {
        tab.value = newTab
        router.replace({ query: { tab: newTab } })
    }
</script>

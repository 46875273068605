<template>
  <div v-if="!data.data.length" class="text-center pt-14 pb-48">
      <p class="text-gray-700 text-base">You don't have any forms yet.</p>
      <NuxtLink to="/form/create?type=form"><button class="text-primary text-sm">Create new form</button></NuxtLink>
  </div>
    <div v-else>
        <div v-for="form in data.data" class="flex items-center h-20 justify-between px-6">
            <span class=" text-xl text-black font-medium"> {{ form.name }} </span>
            <span class=" text-base text-gray-700 text-right ml-auto mr-10">% Completed</span>
            <div class="flex items-center justify-end">
                <NuxtLink :to="`/form/${form.slug}`">
                    <button class="text-black border-b border-black mr-4">View</button>
                </NuxtLink>
                <button class="text-black mr-2 flex items-center">
                    <span class="border-b border-black">Send</span>
                    <font-awesome class="ml-2 h-5 w-5" :icon="['far', 'paper-plane-top']" />
                </button>
            </div>
        </div>
        <span v-if="users.users" class="text-success">
            <strong>Logged in!</strong><br><br>
            <div v-for="user in users.users" class="flex">
                <span v-text="user.email" class="px-2"></span> --- <span v-text="user.name" class="px-2"></span> --- ID: <span v-text="user.id" class="px-2"></span>
            </div>
        </span>
        <span v-else class="m-4 text-danger">
            <strong>Not Logged in</strong><br><br>
        </span>
    </div>
</template>

<script setup lang="ts">
    const { data } = await useFetch('/api/test-org-2/forms', { query: { type: 'form' }})
    const { data: users } = await useFetch('/api/test-org-2/usersAuth');
</script>
